<template>
  <div class="p-grid card">
    <div class="p-col-12">
      <h5>Saques</h5>
    </div>

    <div class="p-col-12 p-md-6 p-md-offset-3 p-text-center">
      <span>
        Seu valor disponível:
        <p-progress-spinner
          v-if="loadingSaldo"
          style="width: 15px; height: 15px"
        />
        <b v-else> {{ $formatCurrency(saldo) }} </b>
      </span>
    </div>
    <div class="p-col-12 p-md-6 p-md-offset-3 p-text-center">
      O valor mínimo para saque é de R$ 200,00.
    </div>
    <div class="p-col-12 p-md-6 p-md-offset-3 p-text-center">
      O prazo para processamento é de 01 a 72 horas úteis.
    </div>

    <div class="p-col-12 p-md-6 p-md-offset-3 p-text-center">
      <p-button
        label="Clique Aqui Para Solicitar"
        @click="openSolicitar"
        :disabled="!$store.state.user?.emailVerifiedAt"
      />
    </div>

    <div class="p-col-12 p-md-6 p-md-offset-3 p-text-center" v-if="msg">
      <p-message severity="warn" :key="msgKey">
        <span v-html="msg" />
      </p-message>
    </div>

    <div
      class="p-col-12 p-md-6 p-md-offset-3 p-text-center"
      v-if="!$store.state.user?.emailVerifiedAt"
    >
      <x-verificar-email />
    </div>

    <div class="p-col-12">
      <Historico />
    </div>
  </div>

  <Solicitar v-model:visible="dialog" :saldo="saldo" />
</template>

<script lang="ts">
import { getSaldos } from "@/services/DashboardService";
import { defineComponent, onMounted, ref } from "vue";
import Solicitar from "./solicitar.vue";
import Historico from "./historico.vue";
import { installControl } from "./control";
import { useStore } from "@/store";

export default defineComponent({
  components: {
    Historico,
    Solicitar,
  },
  setup() {
    const control = installControl();
    const store = useStore();

    const saldo = ref(0);
    const loadingSaldo = ref(true);
    async function loadSaldo() {
      loadingSaldo.value = true;
      try {
        const saldos = await getSaldos();
        saldo.value = parseFloat(saldos.disponivel || "0");
      } finally {
        loadingSaldo.value = false;
      }
    }
    onMounted(loadSaldo);

    const dialog = ref(false);

    const msg = ref<string>("");
    const msgKey = ref<number>(0);
    function setMsg(m: string) {
      msgKey.value++;
      msg.value = m;
    }
    function openSolicitar() {
      if (saldo.value < 200) {
        setMsg("O valor mínimo para saque é de R$ 200,00");
        return;
      }
      if (!store.state.user?.temPwdFinanceira) {
        setMsg(
          "Você ainda não criou sua senha financeira. Para fazer isso agora <a href='#change-password-financeira'>clique aqui</a>"
        );
        return;
      }
      dialog.value = true;
    }

    return {
      msg,
      msgKey,
      openSolicitar,
      control,
      dialog,
      loadingSaldo,
      saldo,
    };
  },
});
</script>

<style scoped>
.pix-key {
  display: flex;
  justify-content: center;
  margin-top: 5px;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px;
}
.pix-key span {
  flex: 1;
}
</style>