
import { getSaldos } from "@/services/DashboardService";
import { defineComponent, onMounted, ref } from "vue";
import Solicitar from "./solicitar.vue";
import Historico from "./historico.vue";
import { installControl } from "./control";
import { useStore } from "@/store";

export default defineComponent({
  components: {
    Historico,
    Solicitar,
  },
  setup() {
    const control = installControl();
    const store = useStore();

    const saldo = ref(0);
    const loadingSaldo = ref(true);
    async function loadSaldo() {
      loadingSaldo.value = true;
      try {
        const saldos = await getSaldos();
        saldo.value = parseFloat(saldos.disponivel || "0");
      } finally {
        loadingSaldo.value = false;
      }
    }
    onMounted(loadSaldo);

    const dialog = ref(false);

    const msg = ref<string>("");
    const msgKey = ref<number>(0);
    function setMsg(m: string) {
      msgKey.value++;
      msg.value = m;
    }
    function openSolicitar() {
      if (saldo.value < 200) {
        setMsg("O valor mínimo para saque é de R$ 200,00");
        return;
      }
      if (!store.state.user?.temPwdFinanceira) {
        setMsg(
          "Você ainda não criou sua senha financeira. Para fazer isso agora <a href='#change-password-financeira'>clique aqui</a>"
        );
        return;
      }
      dialog.value = true;
    }

    return {
      msg,
      msgKey,
      openSolicitar,
      control,
      dialog,
      loadingSaldo,
      saldo,
    };
  },
});
