
import { http } from "@/api/rest";
import {
  getService,
  SolicitarSaqueInput,
  emptySolicitarSaque,
} from "@/services/SaqueService";
import { getService as getUserService, PixKey } from "@/services/UserService";
import { clone } from "@/utils/object";
import { useToast } from "primevue/usetoast";
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useControl } from "./control";
import { useSenhaFinanceira } from "../ChangePasswordFinanceira.vue";

export default defineComponent({
  emits: ["update:visible"],
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    saldo: {
      type: Number,
      required: true,
    },
  },
  setup(props, { emit }) {
    const control = useControl();
    const toast = useToast();

    const solicitando = ref(false);
    const saque = ref<SolicitarSaqueInput>(clone(emptySolicitarSaque));
    const valorLiquido = computed(() => {
      const valor = saque.value.valor - 10;
      return valor > 0 ? valor : 0;
    });

    watch(
      () => props.visible,
      (v) => !v && (saque.value = clone(emptySolicitarSaque))
    );
    function close() {
      if (solicitando.value) {
        return;
      }
      emit("update:visible", false);
    }

    const pixKey = ref<PixKey>();
    const pixKeys = ref<PixKey[]>([]);
    async function loadPixKeys() {
      // loading.value = true;
      try {
        pixKeys.value = await getUserService().getPixKeys();
      } finally {
        // loading.value = false;
      }
    }
    onMounted(loadPixKeys);
    watch(pixKey, (p) => {
      saque.value.chavePix = p?.chave || null!;
    });

    const sendingCode = ref(false);
    async function sendCode() {
      sendingCode.value = true;
      try {
        await http.post("api/users/check-saque", {
          senha_financeira: saque.value.senhaFinanceira,
        });
        toast.add({
          severity: "success",
          summary: "Código enviado",
          life: 3000,
        });
      } finally {
        sendingCode.value = false;
      }
    }
    const canSendCodigo = computed(() => {
      return (
        saque.value.valor && saque.value.chavePix && saque.value.senhaFinanceira
      );
    });

    const {
      gerando: gerandoSenha,
      gerado,
      esqueceuSenha,
    } = useSenhaFinanceira();

    return {
      saque,
      solicitando,
      valorLiquido,
      pixKey,
      pixKeys,
      sendingCode,
      sendCode,
      canSendCodigo,
      esqueceuSenha,
      gerandoSenha,

      close,
      async solicitar() {
        solicitando.value = true;
        try {
          await getService().solicitarSaque(saque.value);
          solicitando.value = false;
          control.historico.find();
          close();
          toast.add({
            severity: "success",
            summary: "Saque solicitado",
            life: 3000,
          });
        } finally {
          solicitando.value = false;
        }
      },

      updateDialogVisible(v: boolean) {
        if (!v && solicitando.value) {
          return;
        }
        emit("update:visible", v);
      },
    };
  },
});
