<template>
  <div class="p-grid card">
    <p-datatable
      ref="dt"
      :value="control.historico.data.value"
      v-model:selection="selectedItens"
      dataKey="id"
      :loading="control.historico.loading.value"
      :first="0"
      :lazy="true"
      :paginator="true"
      :rows="control.historico.dataParams.rows"
      :totalRecords="control.historico.dataParams.totalRecords"
      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      :rowsPerPageOptions="[5, 10, 25]"
      currentPageReportTemplate="{first} - {last} de {totalRecords} registros"
      @page="onPage"
      @sort="onSort"
      responsiveLayout="stack"
      stripedRows
      class="p-col-12"
    >
      <template #header>
        SEU HISTÓRICO DE SAQUES
        <!-- <p-button icon="pi pi-search" @click="find" /> -->
      </template>

      <template #empty>
        <div class="p-text-center p-mt-5 p-mb-5">
          Nenhum registro encontrado!
        </div>
      </template>

      <p-column field="e.id" header="ID" :sortable="true">
        <template #body="{ data }"> #{{ data.id }} </template>
      </p-column>
      <p-column field="e.created_at" header="Data Solicitação" :sortable="true">
        <template #body="{ data }">
          <b>{{ new Date(data.createdAt).toLocaleString() }}</b>
        </template>
      </p-column>
      <p-column field="e.valor" header="Valor Solicitado" :sortable="true">
        <template #body="{ data }">
          <b>{{ $formatCurrency(data.valor) }}</b>
        </template>
      </p-column>
      <p-column field="e.taxa" header="Taxa" :sortable="true">
        <template #body="{ data }">
          {{ $formatCurrency(data.taxa) }}
        </template>
      </p-column>
      <p-column header="Valor Líquido">
        <template #body="{ data }">
          {{ $formatCurrency(data.valor - data.taxa) }}
        </template>
      </p-column>
      <p-column field="e.chavePix" header="Forma Pagamento" :sortable="true">
        <template #body="{ data }"> PIX: {{ data.chavePix }} </template>
      </p-column>
      <p-column header="Status">
        <template #body="{ data }">
          <b>{{ data.status }}</b>
        </template>
      </p-column>
    </p-datatable>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useControl } from "./control";

export default defineComponent({
  data() {
    return {
      cadastroVisible: false,
      deleteItemDialog: false,
      item: {},
      selectedItens: null,
      submitted: false,
    };
  },
  setup() {
    const control = useControl();

    return {
      control,
      find() {
        control.historico.find();
      },
    };
  },
  mounted() {
    this.control.historico.find();
  },
  methods: {
    onPage(evt: any) {
      this.control.historico.dataParams.rows = evt.rows;
      this.control.historico.dataParams.page = evt.page;
      this.find();
    },
    onSort(evt: any) {
      this.control.historico.dataParams.sortField = evt.sortField;
      this.control.historico.dataParams.sortOrder = evt.sortOrder;
      this.find();
    },
  },
});
</script>
