<template>
  <p-dialog
    header="Solicitar Saque"
    :visible="visible"
    @update:visible="updateDialogVisible"
    modal
    style="width: 500px"
  >
    <form class="form" @submit.prevent="solicitar">
      <div class="p-fluid">
        <div class="p-field">
          <label style="width: 100%">
            Disponível: <b>{{ $formatCurrency(saldo) }}</b></label
          >
        </div>
        <div class="p-field">
          <label>R$ Valor do Saque:</label>
          <p-inputnumber
            v-model="saque.valor"
            required
            :minFractionDigits="2"
            :maxFractionDigits="2"
          />
        </div>
        <div class="p-field">
          <label style="width: 100%"> Taxa: R$ 10,00</label>
        </div>
        <div class="p-field">
          <label style="width: 100%"> R$ Valor líquido: </label>
          <p-inputnumber
            v-model="valorLiquido"
            disabled
            :minFractionDigits="2"
            :maxFractionDigits="2"
          />
        </div>
        <div class="p-field">
          <label style="width: 100%"> Chave PIX</label>
          <p-dropdown
            v-model="pixKey"
            :options="pixKeys"
            showClear
            placeholder="Selecione onde quer receber o pagamento"
          >
            <template #value="{ value }">
              <span v-if="value">
                <b>{{ value.descricao }}:</b> {{ value.chave }}
              </span>
              <span v-else>Selecione onde quer receber o pagamento</span>
            </template>
            <template #option="{ option }">
              <b>{{ option.descricao }}:</b> {{ option.chave }}
            </template>
            <template #empty>
              Cadastre uma chave pix no menu Minha Conta
            </template>
          </p-dropdown>
        </div>
        <div class="p-field">
          <label style="width: 100%">
            Informe Sua Senha Financeira
            <span style="float: right">
              <a href="#" @click.prevent="esqueceuSenha">Esqueci minha senha</a>
              <p-progress-spinner
                v-if="gerandoSenha"
                style="width: 15px; height: 15px"
              />
            </span>
          </label>
          <p-inputtext
            required
            type="password"
            v-model="saque.senhaFinanceira"
          />
        </div>
        <div class="p-field">
          <label style="width: 100%">
            Para sua segurança, vamos enviar um código no seu e-mail.
          </label>
          <p-button
            :loading="sendingCode"
            @click="sendCode"
            label="Clique Aqui Para Enviar o Código Agora"
            :disabled="!canSendCodigo"
          />
        </div>
        <div class="p-field">
          <label style="width: 100%">
            Informe o código recebido em seu e-mail:
          </label>
          <p-inputtext v-model="saque.code" required />
        </div>
      </div>

      <div class="p-d-flex p-flex-row-reverse p-jc-start">
        <p-button
          label="Finalizar Solicitação"
          :loading="solicitando"
          type="submit"
        />
        <p-button
          label="Cancelar"
          class="p-button-secondary p-mr-2"
          @click="close"
        />
      </div>
    </form>
  </p-dialog>
</template>

<script lang="ts">
import { http } from "@/api/rest";
import {
  getService,
  SolicitarSaqueInput,
  emptySolicitarSaque,
} from "@/services/SaqueService";
import { getService as getUserService, PixKey } from "@/services/UserService";
import { clone } from "@/utils/object";
import { useToast } from "primevue/usetoast";
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useControl } from "./control";
import { useSenhaFinanceira } from "../ChangePasswordFinanceira.vue";

export default defineComponent({
  emits: ["update:visible"],
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    saldo: {
      type: Number,
      required: true,
    },
  },
  setup(props, { emit }) {
    const control = useControl();
    const toast = useToast();

    const solicitando = ref(false);
    const saque = ref<SolicitarSaqueInput>(clone(emptySolicitarSaque));
    const valorLiquido = computed(() => {
      const valor = saque.value.valor - 10;
      return valor > 0 ? valor : 0;
    });

    watch(
      () => props.visible,
      (v) => !v && (saque.value = clone(emptySolicitarSaque))
    );
    function close() {
      if (solicitando.value) {
        return;
      }
      emit("update:visible", false);
    }

    const pixKey = ref<PixKey>();
    const pixKeys = ref<PixKey[]>([]);
    async function loadPixKeys() {
      // loading.value = true;
      try {
        pixKeys.value = await getUserService().getPixKeys();
      } finally {
        // loading.value = false;
      }
    }
    onMounted(loadPixKeys);
    watch(pixKey, (p) => {
      saque.value.chavePix = p?.chave || null!;
    });

    const sendingCode = ref(false);
    async function sendCode() {
      sendingCode.value = true;
      try {
        await http.post("api/users/check-saque", {
          senha_financeira: saque.value.senhaFinanceira,
        });
        toast.add({
          severity: "success",
          summary: "Código enviado",
          life: 3000,
        });
      } finally {
        sendingCode.value = false;
      }
    }
    const canSendCodigo = computed(() => {
      return (
        saque.value.valor && saque.value.chavePix && saque.value.senhaFinanceira
      );
    });

    const {
      gerando: gerandoSenha,
      gerado,
      esqueceuSenha,
    } = useSenhaFinanceira();

    return {
      saque,
      solicitando,
      valorLiquido,
      pixKey,
      pixKeys,
      sendingCode,
      sendCode,
      canSendCodigo,
      esqueceuSenha,
      gerandoSenha,

      close,
      async solicitar() {
        solicitando.value = true;
        try {
          await getService().solicitarSaque(saque.value);
          solicitando.value = false;
          control.historico.find();
          close();
          toast.add({
            severity: "success",
            summary: "Saque solicitado",
            life: 3000,
          });
        } finally {
          solicitando.value = false;
        }
      },

      updateDialogVisible(v: boolean) {
        if (!v && solicitando.value) {
          return;
        }
        emit("update:visible", v);
      },
    };
  },
});
</script>