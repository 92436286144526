import { getService, Saque } from "@/services/SaqueService";
import { DataTableControl } from "@/services/Services";
import { reactive, Ref, ref } from "@vue/reactivity";
import { inject, nextTick, onMounted, provide, watch } from "@vue/runtime-core";

const CONTROL = Symbol();

interface HistoricoSaque {
  data: Ref<Saque[]>;
  loading: Ref<Boolean>;
  dataParams: DataTableControl;
  find: Function;
}
interface Control {
  historico: HistoricoSaque;
}

function useHistoricoSaques(): HistoricoSaque {
  const data = ref<Saque[]>([]);
  const loading = ref(false);
  const dataParams = reactive<DataTableControl>({
    rows: 10,
    page: 0,
    sortField: "e.id",
    sortOrder: -1,
    totalRecords: 0,
    filter: "",
  });

  async function find() {
    loading.value = true;
    try {
      const result = await getService().consultar({
        // q: dataParams.filter,
        first: dataParams.rows,
        page: dataParams.page + 1,
        sortField: dataParams.sortField,
        sortOrder: dataParams.sortOrder > 0 ? "ASC" : "DESC",
      });
      data.value = result.data;
      dataParams.totalRecords = result.paginatorInfo.total;
    } finally {
      loading.value = false;
    }
  }

  return {
    find,
    data,
    loading,
    dataParams,
  };
}

export function installControl(): Control {
  const control = {
    historico: useHistoricoSaques(),
  };
  provide<Control>(CONTROL, control);

  return control;
}

export function useControl(): Control {
  const control = inject<Control | null>(CONTROL, null);
  if (!control) {
    throw new Error("Provide CONTROL not found");
  }
  return control;
}
