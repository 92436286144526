
import { defineComponent } from "vue";
import { useControl } from "./control";

export default defineComponent({
  data() {
    return {
      cadastroVisible: false,
      deleteItemDialog: false,
      item: {},
      selectedItens: null,
      submitted: false,
    };
  },
  setup() {
    const control = useControl();

    return {
      control,
      find() {
        control.historico.find();
      },
    };
  },
  mounted() {
    this.control.historico.find();
  },
  methods: {
    onPage(evt: any) {
      this.control.historico.dataParams.rows = evt.rows;
      this.control.historico.dataParams.page = evt.page;
      this.find();
    },
    onSort(evt: any) {
      this.control.historico.dataParams.sortField = evt.sortField;
      this.control.historico.dataParams.sortOrder = evt.sortOrder;
      this.find();
    },
  },
});
